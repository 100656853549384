@import 'assets/scss/custom/_variables.scss';

.projects {
  width: 100%;
  .profileLoader {
    height: calc(100vh - 150px) !important;
  }

  .empty-avatar {
    background-color: $gray-300;
  }

  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .project-name-column {
    max-width: 35vw;
  }
  .deliverablesCardHeader {
    padding: 15px;
  }

  .deliverablesUploadButton {
    height: 43px;
    width: 185px;
    color: #ffffff;
    background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    border: 0px;
    border-radius: 4px;
  }

  @media (max-width: 1199px) {
    .project-name-column {
      max-width: 25vw;
    }
  }
  @media (min-width: 1200px) {
    .project-name-column {
      max-width: 31vw;
    }
  }

  .project-name {
    font-weight: 600;
    height: 2rem;
    cursor: pointer;

    a {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }

  :global {
    .react-bootstrap-table.table-responsive {
      min-height: 150px;

      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
    }
  }

  .project-budget,
  .project-status,
  .project-progress {
    color: $gray-600;
  }

  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .addProject {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 500px;
  }
  :global {
    .react-bootstrap-table {
      td {
        vertical-align: middle !important;
      }
    }
  }
}

.dashboard {
  p {
    margin-bottom: 0;
  }
}
.project-profile {
  @media (max-width: 480px) {
    min-height: calc(100vh - 188px);
  }
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    background: $body-bg;
  }
  .project-navbar {
    position: sticky;
    top: 77px;
    z-index: 1 !important;
    background: $body-bg;
    &-timer-on {
      top: 79px !important;
    }
    @media (max-width: 480px) {
      top: 77px;
    }
  }
  .action-bar {
    padding-left: 0 !important;
    .stories-action-container {
      box-shadow: 0 0 1.5rem $primary !important;
    }
  }
}

.hover-hand {
  &:hover {
    cursor: pointer;
  }
}

.content-body {
  width: calc(75% - 4.5rem);
  margin-left: auto;
  &.fullWidth {
    width: calc(100% - 43px - 3.5rem);
  }
}

// calculative width for sections so that it doesn't go down

.add-project-form {
  :global {
    .input-group-alternative {
      box-shadow: none;
      border: 1px solid $gray-400;
    }
  }
}

.input-monthly-hours::-webkit-outer-spin-button,
.input-monthly-hours::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-monthly-hours {
  -moz-appearance: textfield;
}

.fixed-textarea {
  height: 6rem !important;
  resize: none;
}

.invert-icon {
  transform: rotate(180deg);
}
.backlog-container {
  max-height: calc(100vh - 230px);
  //max-width: 75%;
  overflow-y: auto;
  &.no-max-width {
    max-width: unset;
    max-height: unset;
    overflow: hidden;
  }
}
.mainWrapper {
  padding-top: 20px;
  .profileLoader {
    height: calc(100vh - 236px) !important;
  }
}

.selector-dropdown {
  top: 1.75rem !important;
  left: -1.75rem !important;
  width: 12.5rem;
}

.status-dropdown {
  top: 1.25rem !important;
}
