@import 'assets/scss/custom/_variables.scss';

.formLabel {
  font-weight: 600;
  font-size: 15px;
  color: #32325d;
}

.formField {
  input {
    color: #333333;
    &:focus {
      color: #333333;
    }
  }
}

.badge {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: $font-size-xs;
}

.plusIcon {
  background: $gray-400;
  color: $white;
  font-size: 1rem !important;
}

.descriptionWrapper {
  p {
    color: #333333;
  }
  :global {
    .ql-editor {
      color: #333333;
    }
  }
}

.priorityWrapper {
  :global {
    .storyPriority {
      cursor: default;
      padding: 2px 8px;
      border-radius: 11px;
      font-size: 10px;
      font-weight: 600;
      width: fit-content;
    }

    .priorityLow {
      color: #1aae6f;
      background-color: #b0eed3;
    }

    .priorityHigh {
      color: #f80031;
      background-color: #fdd1da;
    }

    .priorityMedium {
      color: #fb6340;
      background-color: #ffe3b8;
    }
  }
}

.taskAlertConfirmButton {
  background: #fb6340;
  border: 0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:hover {
    background-color: #fb6340;
    color: #ffffff;
  }
  &:active {
    background-color: #fb6340 !important;
    color: #ffffff !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.taskAlertCancelBtn {
  border: 0px;
  color: #000000;
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
  &:active {
    background-color: #ffffff !important;
    color: #000000 !important;
    border-color: #ffffff !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.taskAlertPopperText {
  height: 38px;
  width: 455px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #525f7f;
  font-weight: 400;
}
