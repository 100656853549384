@import 'assets/scss/custom/_variables.scss';

.clients {
  width: 100%;
  .page-title {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
  }
  .searchWrapper {
    display: inline-block;
    align-items: baseline;
    width: auto;
    margin-left: 57%;
    font-size: 16px;
    padding: 25px;
    padding-right: 0 !important;
    :global {
      .input-group {
        width: 256px;
        right: 20px;
        position: fixed;
        top: 85px;
        > input {
          height: 40px;
          width: 256px !important;
        }
        .search-box {
          display: flex !important;
          justify-content: center !important;
          flex-direction: row !important;
          align-items: center !important;
          padding: 8px !important;
          width: 256px;
        }
        .search-box > i.fa.fa-search {
          position: absolute !important;
          left: 7% !important;
          right: 16.67% !important;
          color: #a2a0b4 !important;
        }
      }
    }
  }
  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .image {
    max-width: 550px;
  }
  .yearsList {
    width: 165px;
    background: $blue-1;
    height: initial;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    padding: 0;
    overflow: hidden;
    @media (max-width: 449px) {
      width: 100px;
    }
    .year {
      cursor: pointer;
      font-weight: bold;
      color: $gray-700;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      transition: all 0.2s;
      &:hover {
        background: $blue-1;
        color: $primary;
      }
      &:active {
        background: $blue-2;
        color: $peacock-blue;
      }
      i {
        color: #fff;
        margin-right: 8px;
        border-radius: 50%;
        padding: 3px;
        font-size: 14px;
        &.pendingYear {
          color: #0c9119;
          border: 1px dashed #52d75f;
        }
        &.verifiedYear {
          background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
        }
        &.activeYear {
          background: $gray-400;
        }
      }
      padding: 12px 10px;
      &.active {
        transition: all 0.2s;
        background: $white;
        color: $peacock-blue;
      }
      &.disabled {
        background: $blue-1 !important;
        color: $gray-300 !important;
        cursor: not-allowed;
      }
    }
  }
  .details {
    padding: 20px 40px 40px;
    width: 100%;
    height: auto;
    overflow: hidden;
    @media (max-width: 449px) {
      padding: 20px;
    }
    @media (min-width: 449px) and (max-width: 768px) {
      padding: 40px;
    }

    .documentHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      .titleWrapper {
        display: flex;
        flex-direction: column;
        width: 75%;
        @media (max-width: 992px) {
          width: 100%;
        }
      }
      .heading {
        font-size: 24px;
        line-height: 32px;
        color: $gray-900;
        font-weight: bold;
        .separator {
          margin: 0 10px;
        }
        .newDocHeader {
          font-size: 15px;
          background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
        }
        .verifiedHeader {
          font-size: 15px;
          color: #11cdef;
          font-weight: bold;
          i {
            background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .subheading {
        font-size: 14px;
        line-height: 22px;
        color: $gray-900;

        @media (max-width: 991px) {
          width: 100%;
        }
      }

      .reviewButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 16px;
        position: unset;
        left: 82.55%;
        height: 38px;
        bottom: 92.06%;
        background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
        border-radius: 4px;
        &.verify {
          background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
          border-radius: 4px;
        }
        &.undo {
          background: #fff;
          span {
            background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
            i {
              background: inherit;
            }
          }
        }
        i {
          margin-right: 5px;
        }
        @media (max-width: 991px) {
          margin-top: 10px;
        }
      }
    }

    .disabledButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #f7f7f9;
      border-radius: 4px;
      color: #cbc9d6;
      cursor: not-allowed;
      font-size: 0.75rem;
      padding: 5px 16px;
      left: 82.55%;
      height: 38px;
      bottom: 92.06%;
    }

    .pendingButton {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 16px;
      background: #eaffef;
      color: #0c9119;
      border: 1px dashed #52d75f;
      box-sizing: border-box;
      border-radius: 4px;
      &.verified {
        background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border: 1px solid #11cdef;
      }
    }

    .documentGrid {
      display: grid;
      grid-row-gap: 24px;
      grid-column-gap: 24px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;

      @media (max-width: 449px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 449px) and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .otherDocument {
      grid-column: span 2;

      @media (max-width: 768px) {
        grid-column: span 1;
      }
    }
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .titleWrapper {
    display: flex;
    align-items: center;
    .questionIcon {
      margin-right: 10px;
      i {
        color: $primary;
        font-size: 18px;
      }
    }
    h5 {
      margin-bottom: 2px;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.modalContent {
  margin: 24px 0;
  padding: 0 35px;
  li {
    margin-bottom: 30px;
    .warningHeader {
      margin-bottom: 10px;
    }
  }
}
