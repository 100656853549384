@import 'assets/scss/custom/_variables.scss';

.modalWrapper {
  max-width: 520px !important;
  :global {
    .modal-content {
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .title {
    font-weight: bold;
    font-size: 30px;
    color: $gray-800;
  }

  .description {
    font-size: 14px;
    color: $gray-700;
    margin: 16px 0 32px 0;
    text-align: center;
  }

  .buttonGroup {
    display: flex;
  }
}
