@import 'assets/scss/custom/_variables.scss';

.viewed-projects {
  .card-header {
    display: flex;
    justify-content: space-between;
    .tableSubHeader {
      font-size: 14px;
    }
  }
  .card-body {
    .noDataImg {
      max-width: 180px;
      &.loading {
        max-height: calc(130px - 2rem);
      }
    }
    .noDataTitle {
      font-size: 20px;
      font-weight: bold;
      color: $gray-800;
    }
    .noDataDescription {
      font-size: 14px;
      color: $gray-800;
      margin-top: 8px;
      text-align: center;
    }
    &.scroll {
      .tableWrapper {
        max-height: 15rem !important;
        overflow-y: auto;
      }
    }
    .projectTitle {
      padding: $table-head-spacer-y $table-head-spacer-y * 2;
      text-transform: uppercase;
      border-bottom: $table-border-width solid $table-border-color;
      font-size: $table-head-font-size;
      font-weight: $table-head-font-weight;
      color: $table-head-color;
      background-color: $table-head-bg !important;
      border-color: $table-border-color;
    }
    .tableWrapper {
      table {
        table-layout: fixed;
        // thead {
        //   display: none;
        // }
        td {
          vertical-align: middle;
        }
        .row {
          cursor: pointer;
        }
        .project-name {
          span {
            font-weight: $font-weight-bold;
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: unset;
          }
          h5 {
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          i {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
}
