.earnings {
  color: #32325d;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #000000;
  }

  .infoWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    div {
      min-height: 74px;
      width: 265px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: 24px;
      margin-top: 24px;
      padding: 19px 16px;

      background: #ffffff;
      border-radius: 6px;
      filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
        drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
      p {
        margin: 0;
      }
      p:first-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        letter-spacing: -0.01em;
      }
      p + p {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        text-align: right;
        letter-spacing: -0.02em;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .chartWrapper {
    background: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
      drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));

    padding: 22px 30px;
    margin-bottom: 32px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */
      letter-spacing: -0.01em;
      margin-bottom: 20px;
    }

    .barChart {
      height: 200px;
      canvas {
        width: 70% !important;
        padding-bottom: 20px;
      }
    }

    .pieChart {
      height: 200px;
      width: 50%;
    }
  }

  .tableView {
    margin-top: 16px;
    .tableWrapper {
      overflow-x: auto;
      border-radius: 6px;
    }

    table {
      * {
        td {
          vertical-align: middle;
          padding-right: 0.5rem;
        }
      }
      thead {
        border-left: 5px solid #e8e8e8;
        tr {
          th {
            background-color: #e8e8e8;
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
            text-transform: none;
          }
        }
      }
      tbody {
      }
      .row {
        cursor: pointer;
      }
      .addIcon {
        color: #bdbdbd;
        cursor: pointer;
      }
    }

    .caret {
      span {
        margin-left: 10px;
      }
      i {
        color: white;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        width: 30px;
        justify-content: center;
        background: #32325d;
      }
    }

    .paymentWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 8px;
        position: relative;
      }
    }

    .tooltipCustom {
      div {
        display: flex;
        justify-content: space-between;
      }
      hr {
        margin: 0;
      }
    }
  }
  .historyTable {
    max-width: 600px;
  }

  .iconColor {
    color: #525f7f;
  }
}

.noDataWrapper {
  border-radius: 6px;
  overflow: hidden;
}

.emptyStateHeader {
  height: 47px;
  background: #e8e8e8;
  width: 100%;
  margin-bottom: 20px;
}

.noData {
  .iconWrapper {
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    opacity: 0.4;
    padding: 16px;
    border-radius: 50%;
    i {
      font-size: 25px;
    }
  }
}
.defaultText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #cdcdcd;
}
