@import 'assets/scss/custom/_variables.scss';

.container {
  margin-top: -15rem;
  .acceptAndSetup {
    background: $login-card-bg-color;
    border-radius: $login-card-border-radius;
  }
}

.footerMain {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.rootStyles {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.mainContent {
  flex: 1;
}
