@import 'assets/scss/custom/_variables.scss';

.root {
  background: #f7fafc;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 8px;

  .progressBar {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    border-radius: 6px 6px 0px 0px;
    padding: 6px 8px;

    &.onboarding {
      background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    }
    &.document_gathering {
      background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
    }
    &.calculations {
      background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
    }
    &.final_reports {
      background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
    }
    &.complete {
      background: linear-gradient(90deg, #f5365c 0%, #f56636 100%);
    }

    .locked {
      position: absolute;
      right: 12px;
      top: 7px;
      font-size: 12px;
      color: $gray-700;
    }
  }

  .companyName {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $white;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .body {
    padding: 5px 10px;

    .studyRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .studyYear {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #000000;
      }
      .studyCompletion {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #172b4d;
      }
      .studyDeadline {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
      .avatar {
        width: 16px;
        height: 16px;
      }
    }
  }

  .summary {
    color: #32325d;
    font-weight: 600;
    font-size: 10px;
  }
  .taskDetails {
    padding: 5px 10px;
    color: #32325d;
    background: #dce5ef;
    position: relative;
    .grayOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #c4c4c4;
      opacity: 0.4;
    }
    .circleBackground {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      border-radius: 50%;
    }
    .completed {
      background: $white;
      color: $success;
      position: absolute;
      justify-content: center;
      font-size: 10px;
      border-radius: 47%;
      opacity: 0.9;
      i {
        opacity: 1;
      }
    }
    .userAvatar {
      width: 16px;
      height: 16px;
    }
    .noUser {
      background: #757575;
      color: $gray-900;
    }
  }
}

.popoverLocked {
  font-size: 14px;
  text-align: left;
  padding: 15px 10px;
  min-width: 280px;
}

.toggleAnimation {
  transition: 0.5s;
}
