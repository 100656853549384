@import 'assets/scss/custom/_variables.scss';

.fileInputWrapper {
  border: 2px dashed $gray-300;
  border-radius: 10px;
  min-height: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  &:hover {
    background: $blue-1;
    border: 2px solid #53c3f0;
  }

  &.pendingReview {
    border: 2px dashed #52d75f;
  }
  &.verified {
    border: 2px solid #cbc9d6;
  }

  @media (max-width: 449px) {
    padding: 10px;
  }
  @media (min-width: 449px) and (max-width: 768px) {
    padding: 24px;
  }
  @media (min-width: 768px) {
    padding: 24px;
  }
  @media (min-width: 1200px) {
    padding: 43px 78px 22px;
  }

  :global {
    .table-responsive {
      overflow-y: hidden;
    }
    .react-bootstrap-table .table {
      margin: 0;
      border: 0;
    }
    .table thead th {
      font-weight: bold;
      font-size: 10px;
      color: #a2a0b4;
      padding: 4px 0 4px 16px;
    }
    .table {
      thead,
      th,
      tbody,
      tr,
      td {
        border-right: 0;
        border-left: 0;
      }
    }
  }

  p {
    margin: 0;
  }

  .successText {
    margin-top: 50px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #0c9119;
  }

  .errorText {
    margin-top: 50px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #d23535;
  }

  .dropText {
    margin-top: 50px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #2191be;
    text-align: center;
  }

  .loadingText {
    font-weight: bold;
    font-size: 14px;
    color: #2191be;
    margin-top: 50px;
  }

  .moreOption {
    &:hover {
      box-shadow: unset;
    }
  }

  .dragHoverText {
    margin-top: 50px;
    font-weight: bold;
    font-size: 14px;
    color: #0373a0;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    color: #322d52;

    i {
      color: #757190;
    }
  }

  .listHeader {
    background: #f7f7f9;
    padding-left: 15px;
    height: 25px;
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.9px;
    }
  }

  .fileList {
    padding: 0;
    overflow: auto;
    height: 95px;

    .listItem {
      list-style: none;
      padding-left: 15px;
      border-bottom: 1px solid #eeedf5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fileName {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
        white-space: nowrap;
        position: relative;
        margin: 5px 0;
        i {
          color: #d23535;
          margin-right: 5px;
        }
        &.hideRow {
          display: none;
        }
      }
      .isNew {
        margin-right: 20px;
        background: transparent;
        span {
          background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          i {
            background: inherit;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    justify-content: center;

    .buttonPrimary {
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      padding: 5px 16px;
    }
    .integrateButton {
      transform: rotateZ(180deg);
    }
    .buttonOutline {
      border: 1px solid #53c3f0;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      padding: 5px 16px;
    }
    .supportedTypes {
      font-size: 12px;
      color: #757190;
      text-transform: uppercase;
    }
  }
}

.otherDocuments {
  border: 2px dashed #cbc9d6;
  border-radius: 10px;
  padding: 43px 78px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: #f7f7f9;

  @media (max-width: 960px) {
    grid-column: span 1;
  }
}

.afterSuccess {
  padding: 0 !important;
  align-items: unset !important;
}

.hoverDrop {
  background: $blue-1 !important;
  border: 2px solid #53c3f0 !important;
}

.loadingStyle {
  background: $blue-1 !important;
  border: 2px dashed #53c3f0 !important;
}

.success {
  background: $green-1 !important;
  border: 2px dashed #52d75f !important;
}

.error {
  background: $red-1 !important;
  border: 2px dashed #d23535 !important;
}

.cursor {
  cursor: pointer;
}

.titleAfterSuccess {
  font-size: 16px !important;
  margin: 6px 0 3px 13px;
  //width: 100%;
  text-align: left;

  i {
    color: #757190;
  }
}

.pendingText {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #0c9119;
  margin-right: 10px;
}

.syncText {
  color: #fb6340;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
}

.taxType {
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  margin-left: 13px;
}

.file-row {
  overflow-x: hidden;
  .file-title {
    overflow-x: hidden;
    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.icon {
  background: $white;
}

:global {
  .integration-dropdown {
    a.dropdown-item:nth-child(2) {
      color: red !important;
    }
  }
}
