@import 'assets/scss/custom/_variables.scss';

.dollarIcon {
  color: #000;
  font-weight: normal;
  &.hoverHand {
    &:hover {
      cursor: pointer;
    }
  }
  i {
    margin-right: 6px;
    color: #8898aa !important;
    font-size: 12px;
    width: 24px;
    text-align: center;
  }
  .viewModeIcon {
    font-size: 20px;
    width: unset;
  }
  span {
    color: #000000;
    font-size: 14px;
  }
}
.valueItems {
  color: #000000;
  font-size: 17px;
  font-weight: 400;
}
.userInfo {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  .iconWrapper {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    &.phoneIcon {
      transform: rotate(90deg);
    }
  }

  i {
    color: #8898aa;
  }
  .rotateIcon {
    transform: rotate(90deg);
  }
}

.owner {
  margin-bottom: 10px;
}

.title {
  color: #32325d !important;
  font-size: 13px;
  font-weight: 700;
}
.hoverHand {
  width: fit-content;
  margin-top: 5px;
  cursor: pointer;
}

.selectMenu {
  :global {
    .rselect__multi-value:hover {
      .rselect__multi-value__remove {
        cursor: pointer;
      }
    }
  }
}

.roleTitle {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
}

.inputDescription {
  resize: none;
}

.emptyDescription {
  height: 8rem !important;
}

.emptyContent {
  background-color: $gray-100;
}

.description {
  height: max-content;
  border-color: transparent;
  border-radius: 0.5rem;
  white-space: pre-line;
}

.projectOverview {
  h6 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeSpentChart {
  min-height: 35rem !important;
}

.calendar {
  :global {
    .rdtPicker {
      padding: 0.5rem;
      margin-top: -1.5rem !important;
      top: -17.5rem;
    }
  }
  &.end-date {
    :global {
      .rdtPicker {
        right: 0;
      }
    }
  }
}
.recentActivities {
  max-height: 600px;
  overflow: auto;
  padding: 1.5rem 3rem;
}
