@import 'assets/scss/custom/_variables.scss';

.right-column {
  color: $gray-800;
  width: 40%;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 600px;
  }
  .empty-avatar {
    background-color: $gray-300;
  }
  .tabWrapper {
    position: sticky;
    margin-left: -15px;
    top: 0;
    z-index: 9;
    width: calc(100% + 30px);
    padding: 0.5rem 0;
    background: $white;
    border-bottom: 1px solid $gray-200;
    :global {
      .dropdown {
        position: unset;
      }
      .dropdown-menu-lg {
        min-width: 100%;
      }
    }
  }
  .underCollaboration {
    width: 80%;
  }

  .tabItem {
    min-width: 33.33% !important;
    flex: 1 !important;
    padding-right: 0 !important;
    position: relative;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    a {
      display: flex;
      flex-direction: column;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      box-shadow: none !important;
      &:hover,
      &:active,
      &:focus {
        color: $primary !important;
      }
    }
  }

  .badgeCount {
    position: absolute;
    right: 35px;
    top: -7px;
    width: 16px;
    height: 16px;
    font-size: 0.5rem;
    border: 1px solid white;
    @media (max-width: 768px) {
      right: 65px;
    }
    @media (max-width: 576px) {
      right: 48px;
    }
  }
}
