@import 'assets/scss/custom/_variables.scss';

.storyModal {
  @media (max-width: 1200px) {
    min-width: 90%;
  }
  :global {
    .modal-title {
      color: $gray-800;
      font-weight: 600;
      font-size: 17px;
    }
    .pg-viewer-wrapper {
      overflow-y: auto;
      padding: 10px 0;
    }
    .modal-content {
      min-height: 95% !important;
    }
    .pdf-canvas {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: transparent;
      padding-bottom: 10px;
    }
    canvas {
      width: 555px;
    }
    .modal-body {
      padding: 0;
      background: #dfddea;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .fileName {
    font-size: 18px;
    font-weight: bold;
  }

  .downloadIcon {
    cursor: pointer;
  }

  .modalBodyWrapper {
    position: relative;

    .hoverBar {
      position: absolute;
      width: 100%;
      z-index: 999;
      background: #504b70;
      opacity: 0.7;
      padding: 14px 36px 14px 25px;
      top: -70px;
      color: white;
      display: flex;
      justify-content: space-between;
      transition: top 1s;
    }

    &:hover > .hoverBar {
      top: 0;
    }
  }
}

.modalBodyWrapper {
  .pg-viewer-wrapper {
    transition: padding-top 1s;
  }
  &:hover {
    :global {
      .pg-viewer-wrapper {
        padding-top: 55px;
      }
    }
  }
}

.pdfOverflow {
  :global {
    .pg-viewer-wrapper {
      overflow-y: hidden;
    }
    .react-grid-HeaderCell-sortable {
      pointer-events: none;
    }
    canvas {
      width: 100%;
    }
  }
}
