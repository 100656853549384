@import 'assets/scss/custom/_variables.scss';

.root {
  width: 100%;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .desc {
      font-size: 16px;
      line-height: 27px;
      color: #35b0f0;
    }
    .searchBox {
      height: 30px;
      max-width: 250px;
      margin-left: 7px;
    }
    .calendarIcon {
      margin-left: 15px;
      background: #35b0f0;
      height: 32px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 16px;
        color: #fff;
      }
    }
    .switchIcon {
      display: flex;
      .switchText {
        font-size: 14px;
        // line-height: 19px;
        margin-left: 10px;
      }
    }
    .expandCollapse {
      margin-left: 15px;
    }
  }

  .filters {
    padding: 0 30px 30px;
    span {
      padding: 5px 10px;
      background: $gray-200;
      color: #32325d;
      border-radius: 5px;
      font-size: 80%;
      font-weight: 600;
      &:hover {
        a {
          display: inline-block;
        }
      }
      a {
        font-size: 80%;
        font-weight: 600;
        margin-left: 8px;
        color: #32325d;
        display: none;
      }
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  &.fullscreen {
    padding-top: 20px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 1px;
    left: 1px;
    z-index: $zindex-modal;
    background: #f8f9fe;

    .content {
      height: calc(100vh - 12rem);
    }
  }

  .expand {
    color: #32325d;
    cursor: pointer;
  }
  .header {
    font-size: 14px;
    font-weight: normal;

    .subText {
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 375.98px) {
        display: block;
      }
    }

    .headerContainer {
      margin-top: -52px;
    }

    .status {
      font-size: 14px;
      font-weight: 300;
      margin-right: 20px;

      b {
        font-weight: 600;
        font-size: 18px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .content {
    flex: 1;
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    min-height: calc(100vh - 20rem);

    .column {
      margin-right: 20px;
      width: calc(100% / 5);
      display: flex;
      flex-direction: column;
      @media (max-width: 1023.98px) {
        width: 320px;
      }

      .columnHeader {
        display: flex;
        justify-content: space-between;
        h4 {
          font-weight: 600;
          font-size: 17px;
          line-height: 23px;
          color: #212529;
        }
        .badge {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 8px;
          width: 26px;
          height: 27px;
          color: $white;
          &.onboarding {
            background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
          }
          &.document_gathering {
            background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
          }
          &.calculations {
            background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
          }
          &.final_reports {
            background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
          }
          &.complete {
            background: linear-gradient(90deg, #f5365c 0%, #f56636 100%);
          }
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      .list {
        width: 100%;
        @media (max-width: 1023.98px) {
          width: 320px;
        }
        padding: 10px 10px 0 10px;
        margin-top: 10px;
        background: $white;
        flex: 1 0 300px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        &.withFilter {
          margin-top: 6px;
        }
        > div {
          min-height: 100%;
        }
      }
    }
  }

  .expandCollapse {
    cursor: pointer;
    color: #35b0f0;
    margin-left: 6px;
  }
}
.dropdown {
  margin: 0.125rem 0 0 0.125rem;
  .chevron {
    transform: rotate(90deg);
  }
  button {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    font-size: 17px;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .dropdownItem {
    font-size: 14px;
    line-height: 19px;
    color: $gray-700;
  }
}

.disablePointer {
  cursor: default !important;
}

.dragContainerBtnStyle {
  color: #000000;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  box-shadow: none !important;
  &:hover {
    color: #000000;
    border: 0px solid #ffffff;
    background-color: #ffffff;
    box-shadow: none !important;
  }
  &:active {
    background-color: #ffffff !important;
    color: #000000 !important;
    border-color: #ffffff !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
