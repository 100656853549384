.clientStudies {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerContent {
      display: grid;
      align-items: center;
      margin-left: 14px;
      grid-template-columns: 2fr 1fr 1fr;
      .clientName {
        margin-right: 70px;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
        }
        .active_study {
          color: #525f7f;
        }
      }
      div {
        margin-right: 32px;
        h3 {
          margin-bottom: 0;
          font-size: 18px;
          line-height: 25px;
          font-weight: 600;
        }
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #32325d;
        }
        p:first-child {
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          color: #4f4f4f;
        }
      }
    }
    .addIcon {
      color: #bdbdbd;
      cursor: pointer;
    }
  }
  .studyDetails {
    margin-top: 20px;
    .studyDetailsWrapper {
      display: flex;
      gap: 15px;
      margin-bottom: 10px;
      align-items: center;
      .studyType {
        width: 15%;
        h3 {
          font-weight: 700;
          font-size: 13px;
          line-height: 18px;
          color: #32325d;
          margin-bottom: 0;
        }
        p {
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          color: #525f7f;
          margin-bottom: 0;
        }
      }
      .fullBar {
        div {
          height: 16px;
          background-color: #4fb3c9 !important;
          border-radius: 8px;
        }
      }
      .paidInFull {
        width: 15%;
        display: flex;
        flex-direction: column;
        position: relative;
        div {
          height: 16px;
          background-color: #f0f3f5;
          border-radius: 8px;
        }
        p {
          position: absolute;
          top: -20px;
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          margin-bottom: 0;
        }
      }
      .studyProgression {
        width: 70%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        height: 16px;
        border-radius: 8px;
        grid-gap: 1px;
        .barWrapper {
          display: flex;
          flex-direction: column;
          position: relative;
          .activeBar {
            background-color: #4fb3c9;
          }
          .completedStatus {
            background-color: #4fb3c9;
          }
          .halfReport {
            background: linear-gradient(to right, #4fb3c9 50%, #f0f3f5 50%);
          }
          .firstChild {
            border-radius: 8px 0px 0px 8px;
          }
          .lastChild {
            border-radius: 0px 8px 8px 0px;
          }
          .onHoldBar {
            background: repeating-linear-gradient(
              135deg,
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.1) 3px,
              rgba(0, 0, 0, 0.1) 3px,
              rgba(0, 0, 0, 0) 5px
            ) !important;
          }
        }
        p {
          position: absolute;
          top: -20px;
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          margin-bottom: 0;
          color: #4f4f4f;
        }
        .bar {
          height: 16px;
          background-color: #f0f3f5;
        }
      }
    }
  }
}
