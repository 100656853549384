@import '../../assets/scss/custom/variables';

/*This will restrict the behaviour of sidebar remaining open after clicking close*/
.sidebar {
  :global {
    .infoTitle {
      display: none;
    }

    body.g-sidenav-hidden.g-sidenav-show {
      .sidenav:hover {
        max-width: 250px;
      }

      .infoTitle {
        display: flex;
      }
    }

    body.g-sidenav-hidden {
      .sidenav:hover {
        max-width: 0px;
      }
    }

    .icon-gradient {
      background-clip: text !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
    }

    .icon-dashboard {
      background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    }

    .icon-documents {
      background: linear-gradient(90deg, #11cdef 0%, #1171ef 100%);
    }

    .icon-studies {
      background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
    }

    .icon-diamond {
      background: $primary;
    }

    .icon-invite-user {
      background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
    }

    .navbar-vertical.navbar-expand-xs
      .navbar-nav
      > .nav-item
      > .nav-link.active {
      background: unset;
    }

    .navbar-brand-img {
      max-height: 35px !important;
    }

    .nav-link {
      transition: all 0.1s;
      color: $gray-700 !important;

      .nav-link-text {
        font-size: 14px;
        line-height: 22px;
      }

      &:hover {
        background: $blue-1;
        color: $primary;

        * {
          color: $primary;
        }
      }

      &:active {
        background: $blue-1;
        color: $primary;

        * {
          color: $primary;
        }
      }
    }
  }

  .footerNav {
    position: absolute;
    bottom: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .hideButton {
    display: none;
  }
  .partnerActions {
    border-top: 1px solid #c4c4c4;
    padding-top: 24px;
    margin-top: 16px;
    .actionButtons {
      display: flex;
      flex-direction: column;
      gap: 24px;
      button {
        margin-left: 0;
        margin-right: 0;
      }
    }
    p {
      margin-top: 16px;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      color: #8898aa;
    }
  }

  .navLink {
    .active {
      background: $blue-1;
      border-right: 3px solid $primary;
      color: $primary !important;
    }

    &.disabled {
      background: $secondary !important;
      color: $gray-300 !important;
      cursor: not-allowed;

      * {
        color: $gray-300 !important;
      }
    }
  }
}
.modalContent {
  p {
    color: #525f7f;
  }
}
.meetingModalWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  .meetingDescription {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    .header {
      margin: 60px auto;
      text-align: center;

      img {
        width: 150px;
      }
    }
    .content {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 24px 32px;
      h1 {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 22px;
        line-height: 16px;
        color: #000000;
      }
      h5 {
        margin-bottom: 16px;
        display: flex;
        gap: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #737373;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 24px;
      }
      a {
        font-size: 14px;
      }
    }
  }

  .dateSelection {
    padding: 24px 32px;
    .timeZone {
      margin-top: 35px;
      h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
      p {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: #000000;
      }
      .dropdownCaret {
        :global {
          .show {
            height: 400px;
            right: 0 !important;
            overflow: auto;
            left: auto !important;
            transform: unset !important;
          }
        }
      }
    }
    :global {
      .DayPicker__withBorder {
        box-shadow: none;
      }
      .CalendarDay__default {
        border: none;
      }
      .CalendarDay__selected {
        background: rgba(203, 195, 227, 0.4);
        border-radius: 50%;
        color: #5e72e4;
        font-weight: 700;
      }
      .CalendarDay__selected_span {
        background: rgba(203, 195, 227, 0.4);
        border-radius: 50%;
        color: #5e72e4;
        font-weight: 700;
      }
      .CalendarMonth_caption {
        padding-top: 0;
        text-align: left;
        strong {
          font-weight: 400 !important;
        }
      }
      .DayPickerNavigation__horizontal {
        display: flex;
        justify-content: flex-end;
        margin-right: 35px;
        gap: 35px;
      }
      .DayPicker_weekHeader {
        top: 37px;
        color: black;
        padding: 0 !important;
      }
      .CalendarMonth_table {
        border-spacing: 4px;
        border-collapse: separate;
      }
      .CalendarMonth {
        padding: 0 !important;
      }
      .DayPicker_weekHeader_li {
        text-align: left;
        margin-right: 5px;
      }
      .DayPickerKeyboardShortcuts_showSpan__bottomRight {
        display: none;
      }
      .DayPickerKeyboardShortcuts_show::before {
        display: none;
      }
      .CalendarMonthGrid__horizontal {
        position: initial;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      margin-bottom: 28px;
    }
  }
}

.referralAlert {
  p {
    flex-direction: row-reverse;
  }
  a:first-child {
    color: #525f7f;
    border: 1px solid #525f7f;
  }
  h2 {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 33px;

    color: #32325d !important;
  }

  .text {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 19px;

    color: #525f7f !important;
  }
}

.partnerCancelBtn {
  border: none !important;
  background-color: #fb6340;
  color: white !important;
}

.referralForm {
  h5 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;

    /* Text Color/Dark Blue */
    color: #525f7f;
  }
  .required::after {
    content: '*';
    margin-left: 0.5em;
    color: #ff0000;
  }

  .typeWrapper {
    display: flex;
    max-width: fit-content;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    overflow: hidden;

    .type {
      padding: 12px 24px;
      cursor: pointer;
      color: #32325d;

      &.active {
        color: white;
        background: #32325d;
      }
    }
  }
}
