.root {
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .rotate180 {
    transform: rotate(180deg);
  }
}
.navBar {
  position: sticky;
  top: 0;
  z-index: 9;
}
.notificationDropdown {
  max-width: 420px;
  @media (max-width: 480px) {
    width: calc(100vw - 10px) !important;
    min-width: unset;
  }
}
.dropdown-link {
  color: rgba(0, 0, 0, 0.9);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}

.impersonateBar {
  font-family: Open Sans;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .norm {
    margin-right: 8px;
  }
  .bold {
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;

    margin-right: 24px;
  }

  .exitBtn {
    display: block;
    min-height: 44px;
    background: #2dce89;
    /* Input Form Controls/Default */
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
    border-radius: 4px;
    padding: 12px 18px;

    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;

    cursor: pointer;
  }
}
