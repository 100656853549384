.label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height */
  letter-spacing: -0.005em;
  /* Text Color/Dark Blue */
  color: #525f7f;
}
