.lineheight {
  line-height: 30px;
  font-size: 13px;
  font-weight: 600;
  color: #525f7f;
  span {
    font-weight: 400;
  }
}

.preAnaProgressBar {
  background: linear-gradient(90deg, #8965e0 0%, #bc65e0 100%);
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
}
.docGatProgressBar {
  margin-left: 2px;
  background: linear-gradient(90deg, #11cdef 8.33%, #1171ef 88.89%);
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
}
.calProgressBar {
  margin-left: 2px;
  background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
}

.unFinishedProgressBar {
  margin-left: 2px;
  background: #d2e3ee !important;
}

.finalProgressBar {
  margin-left: 2px;
}

.progress {
  height: 10px;
  margin-bottom: 2px;
  background: #ffffff;
  .successProgressBar {
    margin-left: 2px;
  }
}

.progressBarLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  color: #8898aa;
  .selectedLabel {
    font-weight: 700;
    color: #32325d;
  }
}

.headerSection {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 35px;
  text-align: left;
  color: #212529;
  display: flex;
  img {
    margin-right: 7px;
  }
}

.activeStudyTitle {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
  color: #32325d;
}
.completedStudyTitle {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  color: #32325d;
}

.completedCardSection {
  display: flex;
  //justify-content: space-between;
  //width: 70%;
  flex-wrap: wrap;
  .completedCards {
    margin: 5px 20px 0 0;
    height: 80px;
    width: 210px;
    background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
    border-radius: 4px;
    color: #ffffff;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-weight: 700;
    font-size: 13px;
  }
}

.descriptionText {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-decoration-line: underline;
  &:hover {
    color: #0ca8eb;
    text-decoration-line: underline;
  }
  &:focus-within {
    box-shadow: none !important;
  }
}

.descriptionBodyText {
  font-weight: 400;
  font-size: 13px !important;
  line-height: 18px;
  color: #4f4f4f;
}
