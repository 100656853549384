.documents {
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .newTaskButton {
    color: #fff;
    background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .uploadDocButton {
    color: #fff;
    background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .noDataWrapper {
    height: calc(100vh - 380px);
  }
  .image {
    max-width: 225px;
  }
  .imageWrapper {
    display: flex;
    width: 150px;
    height: 150px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .defaultImage {
    max-width: 45px;
  }
  .defaultText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #cdcdcd;
  }
  :global {
    .react-bootstrap-table.table-responsive {
      min-height: 150px;

      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
        tr {
          cursor: pointer;
        }
        .file-name {
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }

      @media (max-width: 992px) {
        table {
          width: auto;
        }
      }
    }
  }
}
