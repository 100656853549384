.deliverableCard {
  max-height: 200px;
  overflow: auto;
  &.hideScroll {
    overflow: visible;
  }
  &.defaultHeight {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .deliverableTitle {
    cursor: pointer;
  }
}
.cardfixedheight {
  max-height: 210px !important;
  min-height: 210px !important;
}
.listitem {
  position: absolute;
  left: 36.06%;
  right: 4.69%;
  top: 36.06%;
  bottom: 82.81%;
}
.listBackground {
  position: absolute;
  width: 61px;
  height: 61px;
  left: calc(100% - 58%);
  top: 78px;

  background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
  opacity: 0.4;
  border-radius: 50%;
}
.listText {
  position: absolute;
  width: auto;
  background: no-repeat;
  top: 150px;
  left: calc(100% - 64%);
  opacity: 1;
  font-size: 14px;
  color: #cdcdcd;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  &.deliverablePlaceHolder {
    width: 100%;
    left: unset;
  }
}
