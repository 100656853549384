.cursor {
  cursor: pointer;
}
.badgeCount {
  position: absolute;
  right: 17px;
  top: 15px;
}
.desktopTabs {
  width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 75%;
  }
  // &:last-of-type {
  //   margin-right: -1.5rem;
  // }
}
